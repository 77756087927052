<div style="margin: 0; padding: 0">
  @for (field of getContactAsArray(); track field.label) {
    <div class="footer-info-contact">
      <span><strong>{{ field.label }}</strong> <a [href]="field.ref"> {{ field.value }}</a></span>
    </div>
  }
  <!-- Start Subscribe Area -->
  <form [formGroup]="getForm()" class="newsletter-form">
    <input formControlName="email" type="email" class="input-newsletter" placeholder="Subscription email" autocomplete="off">
    <button class="default-btn"
            [style.background-color]="(requestStatus() === 'None') ? '' : 'rgba(var(--bs-light-rgb),var(--bs-bg-opacity))'"
            type="button" (click)="onSubmit()">
      @if (requestStatus() === 'InProgress') {
        <div class="request-loading">
          <i class="spinner-border spinner-border-sm" aria-hidden="true"></i>
        </div>
      } @else if (requestStatus() === 'Successful') {
        <div class="request-success">
          <i class="fa-solid fa-circle-check"></i>
        </div>
      } @else if (requestStatus() === 'Failed') {
        <div class="request-failed">
          <i class="fa-solid fa-circle-xmark"></i>
        </div>
      } @else {
        <i class="pe-7s-angle-right-circle"></i><span></span>
      }
    </button>
  </form>
  <!-- End Subscribe Area -->
</div>
