import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from "@angular/router";
import {AppRouterModule} from "./app-router.module";
import {MainModule} from "./module/main/main.module";
import {LawModule} from "./module/law/law.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ContactService} from "./data/services/contact.service";
import {UrlInterceptor} from "./core/interceotors/url.interceptor";
import {DefaultSubscriptionService} from "./data/services/default-subscription.service";
import {ErrorPageModule} from './module/error/error-page.module';
import {GoogleTagManagerModule, GoogleTagManagerService} from "angular-google-tag-manager";
import {SEOService} from "./core/services/seo.service";
import {environment} from "../environments/environment";
import {LayoutComponent} from "./layout/layout/layout.component";
import {NavBarComponent} from "./layout/nav-bar/nav-bar.component";
import {CopyrightComponent} from "./layout/copyright/copyright.component";
import {CookieModalComponent} from "./layout/cookie-modal/cookie-modal.component";
import {UnsubscriptionModule} from "./module/unsubscription/unsubscription.module";
import {CoreModule} from "./core/core.module";
import {DataModule} from "./data/data.module";
import {SharedModule} from "./shared/shared.module";
import {DropdownNavBarItemComponent} from "./layout/nav-bar/dropdown-nav-bar-item/dropdown-nav-bar-item.component";
import {SimpleNavBarItemComponent} from "./layout/nav-bar/simple-nav-bar-item/simple-nav-bar-item.component";

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    NavBarComponent,
    SimpleNavBarItemComponent,
    DropdownNavBarItemComponent,
    CopyrightComponent,
    CookieModalComponent
  ],
  imports: [
    // angular
    BrowserModule,
    BrowserAnimationsModule,
    // 3rd party
    GoogleTagManagerModule.forRoot({id: `GTM-${environment.gtmId}`}),
    // core & shared
    CoreModule,
    DataModule,
    SharedModule,

    // app
    MainModule,
    LawModule,
    ErrorPageModule,
    UnsubscriptionModule,
    AppRouterModule
  ],
  providers: [
    GoogleTagManagerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
