<app-section name="what-we-offer">
  <div class="col-lg-6" style="margin: auto 0">
    <div class="overview-content">
      <h2>What we offer?</h2>
      <p>Our company offers comprehensive DevOps as a Service solutions tailored to accelerate and streamline your
        software development and delivery processes. Our expert team of DevOps engineers provides end-to-end support,
        including infrastructure setup, continuous integration/continuous deployment (CI/CD) implementation, automated
        testing, monitoring, and cloud management. With our DevOps expertise, you can achieve faster development cycles,
        higher software quality, and seamless collaboration between development and operations teams, enabling you to
        stay ahead in the competitive market and achieve your business goals with confidence.</p>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="overview-image">
      <img ngSrc="assets/img/choose-2.png" priority="3" alt="image" height="500" width="600">
    </div>
  </div>
</app-section>
