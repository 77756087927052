import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MainComponent} from "./main.component";
import {HomeComponent} from "./components/home/home.component";
import {WhatWeDoComponent} from "./components/what-we-do/what-we-do.component";
import {WhoWeAreComponent} from "./components/who-we-are/who-we-are.component";
import {WhatWeOfferComponent} from "./components/what-we-offer/what-we-offer.component";
import {FooterComponent} from "./components/footer/footer.component";
import {RouterModule} from "@angular/router";
import {WayToExcellenceComponent} from "./components/way-to-excellence/way-to-excellence.component";
import {ContactFormComponent} from "./components/contact-form/contact-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MainRoutingModule} from "./main-routing.module";
import {
  ContactFormAnimationComponent
} from "./components/contact-form/contact-form-animation/contact-form-animation.component";
import {FooterContactBlockComponent} from "./components/footer/footer-contact-block/footer-contact-block.component";
import {SharedModule} from "../../shared/shared.module";
import {ServiceItemComponent} from "./components/what-we-do/service-item/service-item.component";
import {DefaultSubscriptionService} from "../../data/services/default-subscription.service";
import {ContactService} from "../../data/services/contact.service";


@NgModule({
  declarations: [
    MainComponent,
    HomeComponent,
    WhatWeDoComponent,
    WhoWeAreComponent,
    WhatWeOfferComponent,
    FooterComponent,
    FooterContactBlockComponent,
    WayToExcellenceComponent,
    ContactFormComponent,
    ContactFormAnimationComponent,
    FooterContactBlockComponent
  ],
  providers: [
    ContactService,
    DefaultSubscriptionService
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    SharedModule,
    ServiceItemComponent
  ],
  exports: [RouterModule]
})
export class MainModule {
}
