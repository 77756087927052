<app-section name="way-to-excellence">
  <div class="col-lg-6">
    <div class="overview-image">
      <img ngSrc="assets/img/way-to-excellence.png" priority="4" alt="image" height="450" width="800">
    </div>
  </div>
  <div class="col-lg-6">
    <div class="overview-content">
      <h2>Our way to excellence</h2>
      <p>Driven by a clear path to excellence with...</p>
      <ul class="features-list">
        @for (card of excellenceOptions; track card.name) {
          <li>
            <span [class]="card.class">{{ card.name }}</span>
          </li>
        }
      </ul>
    </div>
  </div>
</app-section>
