import {Component, OnInit, signal, WritableSignal} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {catchError, finalize, Observable, switchMap, take, timer} from "rxjs";
import {ContactService} from "../../../../data/services/contact.service";
import {IRequestProcessing} from "../../../../core/interfaces/IRequestProcessing";
import {HttpErrorResponse} from '@angular/common/http';
import {RequestProcessingType} from "../../../../core/types/RequestProcessingType";
import {errorMessages} from "../../../../core/constants/error-messages";

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss', '../section-common.scss']
})
export class ContactFormComponent implements OnInit, IRequestProcessing<any> {

  form: FormGroup;
  requestStatus: WritableSignal<RequestProcessingType> = signal('None');

  constructor(private fb: FormBuilder,
              private contactService: ContactService) {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.email, Validators.required])],
      company: [''],
      phone: ['', Validators.pattern('^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})')]
    })
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.requestStatus() === 'None' && this.form.valid) {
      this.requestStatus.set('InProgress');
      this.contactService.sendContactInfoToServer(this.form.getRawValue())
        .pipe(
          switchMap(() => this.onRequestSuccess(null)),
          take(1),
          catchError(err => this.onRequestError(err)),
          finalize(() => this.onRequestComplete()))
        .subscribe()
    }
  }

  isValid(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (control) {
      return control.valid && control.touched;
    }
    return false;
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (control) {
      return control.invalid && control.touched;
    }
    return false;
  }

  getErrorMessage(controlName: string): string {
    const control = this.form.get(controlName);
    if (control && control.errors) {
      const errorName = Object.keys(control.errors)[0];
      if (errorName === 'pattern') {
        return errorMessages[controlName + '_' + errorName]
      } else {
        return errorMessages[errorName];
      }
    }
    return '';
  }

  onRequestSuccess(value: any): Observable<any> {
    this.requestStatus.set('Successful');
    this.form.patchValue({email: '', name: '', company: '', phone: ''});
    return timer(5000);
  }
  onRequestError(err: HttpErrorResponse): Observable<any> {
    this.requestStatus.set('Failed');
    return timer(5000);
  }
  onRequestComplete(): void {
    this.requestStatus.set('None');
  }
}
