import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-root',
  styles: ':host(app-root) {height: 100%;display: block;}',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

  ngOnInit(): void {
  }
}
