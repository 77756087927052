import { Component } from '@angular/core';
import {DataEntityHolder} from "../../../../core/types/data-entity-holder";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss', "footer-common.scss"]
})
export class FooterComponent {

  protected readonly env = environment;

  footerSocial: DataEntityHolder[] = [
    { name: 'LinkedIn', icon: 'fa-brands fa-linkedin', link: this.env.socialLink.linkedIn },
    // { name: 'Facebook', icon: 'fa-brands fa-square-facebook', link: this.env.socialLink.facebook},
    { name: 'Upwork', icon: 'fa-brands fa-upwork', link: this.env.socialLink.upwork },
    // { name: 'Twitter', icon: 'fa-brands fa-square-x-twitter', link: '#'},
  ]

  footerQuickLinksServices: DataEntityHolder[] = [
    { name: 'Organize DevOps Flow', fragment: '' },
    { name: 'Cloud migration', fragment: '' },
    { name: 'Infrastructure automation', fragment: '' },
    { name: 'Performance optimization', fragment: '' },
    { name: 'Budget auditing', fragment: '' },
    { name: 'Team Management', fragment: '' }
  ]

  footerQuickLinks: DataEntityHolder[] = [
    { name: 'About Us', link: '/', fragment: 'who-we-are' },
    { name: 'Privacy Policy', link: '/law/policy', fragment: 'policy-top' },
    { name: 'Terms & Conditions', link: '/law/terms', fragment: 'terms-top' },
  ]
}
