import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ISubscriptionService} from "../../core/interfaces/ISubscriptionService";

@Injectable({
  providedIn: "root"
})
export class DefaultSubscriptionService implements ISubscriptionService {

  constructor(private http: HttpClient) {
  }

  public subscribeOnNews(obj: { email: string }): Observable<any> {
    return this.http.post("news", {...obj, state: 'NEW'}, {responseType: 'text'});
  }

  public unsubscribeFromNews(userId: string): Observable<any> {
    return this.http.post("news", {user_id: userId, state: 'STOP'}, {responseType: "text"});
  }

  public resubscribeOnNews(userId: string): Observable<any> {
    return this.http.post("news", {user_id: userId, state: 'START'}, {responseType: "text"});
  }

  public sendFeedback(obj: any): Observable<any> {
      return this.http.post(`news`, {...obj, state: 'FEEDBACK'}, {responseType: "text"})
  }
}
