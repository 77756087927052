<div class="copyright-area">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-xl-start text-lg-start text-md-start text-center">
        <ul>
          <li> <a routerLink="/law/terms" fragment="terms-top">Terms &amp; Conditions</a>
          </li>
          <li> <a routerLink="/law/policy" fragment="policy-top">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-xl-end text-lg-end text-md-end text-center">
        <p> <i class="fa fa-copyright"></i> 2023 {{ env.officialCompanyName }} - All Rights Reserved.</p>
      </div>
    </div>
  </div>
</div>
