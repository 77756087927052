<div class="w-33">
  <h2>Thank you!</h2>
  <p>You have been successfully removed from this subscriber list and won't receive any further emails from us.</p>
  <p>Did you unsubscribed by accident? <span class="link-primary" (click)="resubscribeOnNews()"><u>Re-subscribe</u></span></p>
  <p><strong>If you have a moment, please let us know why you unsubscribed:</strong></p>
  @for (answer of getAnswerObjects(); track answer.order) {
    <div class="form-check radio-select">
      <input type="radio"
             [id]="'flexRadioDefault' + answer.order"
             [name]="'flexRadioDefault'"
             [value]="answer.order"
             [(ngModel)]="answerNumber">
      <label class="form-check-label" style="margin: 7px" [for]="'flexRadioDefault' + answer.order">
        <p>{{ answer.message }}</p>
      </label>
    </div>
  }
  <div class="mt-2 mb-4">
    <label for="otherInput" class="form-label"><p>Other answer:</p></label>
    <input type="email" class="form-control" id="otherInput" [disabled]="answerNumber !== 3" [(ngModel)]="customAnswer"/>
  </div>
  <button type="button" class="btn btn-outline-primary" (click)="sendFeedback()" [disabled]="feedbackInProgress()">Send Feedback</button>
</div>
