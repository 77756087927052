import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UnsubscriptionComponent} from "./unsubscription.component";
import {RouterModule} from "@angular/router";
import {LayoutComponent} from "../../layout/layout/layout.component";
import {SharedModule} from "../../shared/shared.module";
import {FormsModule} from "@angular/forms";
import {DefaultSubscriptionService} from "../../data/services/default-subscription.service";


@NgModule({
  declarations: [
    UnsubscriptionComponent
  ],
  providers: [
    DefaultSubscriptionService
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,

    RouterModule.forChild([
      {
        path: '',
        component: LayoutComponent,
        children: [
          {
            path: '',
            component: UnsubscriptionComponent
          }
        ]
      }
    ]),
  ]
})
export class UnsubscriptionModule { }
