import {Component, OnInit} from '@angular/core';
import {SEOService} from "../../core/services/seo.service";

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private seoService: SEOService) {
  }

  ngOnInit(): void {
    this.seoService.setPageTitle('Cloudninja — Innovate, Integrate, Inspire');
  }
}
