import {Injectable, signal, WritableSignal} from '@angular/core';
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {CookieOptions, CookieService} from "ngx-cookie-service";
import {environment} from "../../environments/environment";
import {CookieLevelType} from "../core/types/CookieLevelType";

@Injectable({
  providedIn: 'root'
})
export class CookieModalService {

  private initialCookieModalOpened: WritableSignal<boolean> = signal(false);
  private manageCookieModalOpened: WritableSignal<boolean> = signal(false);
  private readonly COOKIE_LEVEL_NAME: string = 'cookie_lvl';
  private readonly COOKIE_ACCEPTED_NAME: string = 'cookie_accepted';
  private readonly DEFAULT_COOKIE_OPTION: CookieOptions = {
    path: '/',
    sameSite: 'Lax',
    secure: true
  }

  constructor(private cookieService: CookieService,
              private gtmService: GoogleTagManagerService) {
    this.DEFAULT_COOKIE_OPTION = {
      path: '/',
      sameSite: 'Lax',
      domain: environment.cookieBaseDomain.search("localhost") < 0 ? undefined : environment.cookieBaseDomain,
      expires: 400,
      secure: true
    };
  }

  initializeCookieFlow(): void {
    if (this.cookieService.check(this.COOKIE_ACCEPTED_NAME)) {
      this.setCookies(this.cookieService.get(this.COOKIE_LEVEL_NAME).split(','));
    } else {
      this.openCookieModal();
    }
  }

  acceptAllCookieAnswer(): void {
    this.acceptSelectedCookies(['All']);
  }

  acceptSelectedCookies(acceptedCookieTypes: CookieLevelType[]): void {
    this.setCookieAcceptance(acceptedCookieTypes);
    this.setCookies(acceptedCookieTypes);
    this.closeCookieModal();
  }

  isModalOpened(): boolean {
    return this.initialCookieModalOpened();
  }

  isManagedModalOpened(): boolean {
    return this.manageCookieModalOpened();
  }

  openCookieModal(): void {
    this.initialCookieModalOpened.set(true);
  }

  openCustomizeModal() {
    this.manageCookieModalOpened.set(true);
  }

  closeCookieModal(): void {
    this.initialCookieModalOpened.set(false);
  }

  private setCookieAcceptance(acceptedCookieTypes: CookieLevelType[]): void {
    this.cookieService.set(this.COOKIE_ACCEPTED_NAME, String(true), this.DEFAULT_COOKIE_OPTION);
    this.cookieService.set(this.COOKIE_LEVEL_NAME, String(acceptedCookieTypes.join(',')), this.DEFAULT_COOKIE_OPTION)
  }

  private setCookies(acceptedCookieTypes: CookieLevelType[] | string[]): void {
    if (acceptedCookieTypes.find(type => type === 'Required' || type === 'All')) {}
    if (acceptedCookieTypes.find(type => type === 'Performance' || type === 'All')) {}
    if (acceptedCookieTypes.find(type => type === 'Functional' || type === 'All')) {}
    if (acceptedCookieTypes.find(type => type === 'Targeting' || type === 'All')
      && !this.cookieService.check('_ga*')) {
      this.gtmService.addGtmToDom();
    }
    if (acceptedCookieTypes.find(type => type === 'Social Media' || type === 'All')) {}
  }
}
