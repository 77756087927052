import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {LawRoutingModule} from './law-routing.module';
import {TermsComponent} from './terms/terms.component';
import {PolicyComponent} from './policy/policy.component';
import {SharedModule} from "../../shared/shared.module";


@NgModule({
  declarations: [
    TermsComponent,
    PolicyComponent
  ],
  imports: [
    CommonModule,
    LawRoutingModule,
    SharedModule
  ]
})
export class LawModule { }
