<nav class="nav navbar navbar-expand-lg bg-body-tertiary" [ngClass]="monitorScrolled() ? 'scroll-shadow' : ''">
  <div class="container">
    <div class="navbar-brand" routerLink="/">
      <span class="logo-sharp-text">#:</span>
      <span class="logo-company-text">Cloudninja</span>
      <span class="logo-carret" [ngClass]="monitorScrolled() ? 'logo-carret-blink-stopped' : 'logo-carret-blink-active'">!</span>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCentered"
            aria-controls="navbarCentered" aria-expanded="false" aria-label="Toggle navigation">
      <span><i class="fa fa-bars"></i></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarCentered">
      <ul class="navbar-nav">
        @for (route of getAsArray(); track route.label) {
          <div class="nav-item-own">
          @if (route.dropdown) {
            <app-dropdown-nav-bar-item [data]="route"></app-dropdown-nav-bar-item>
          } @else {
            <app-simple-nav-bar-item [data]="route"></app-simple-nav-bar-item>
          }
          </div>
        }
      </ul>
    </div>
  </div>
</nav>
