import { Injectable } from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class SEOService {

  constructor(private metaTagService: Meta,
              private titleService: Title) {
  }

  public setPageTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  public getPageTitle(): string {
    return this.titleService.getTitle();
  }

  public addMetaTag(name: string, content: string): void {
    this.metaTagService.addTag({ name, content });
  }

  public getMetaTag(selector: string): HTMLMetaElement | null {
    return this.metaTagService.getTag(selector);
  }

  public remoteMetaTag(selector: string): void {
    this.metaTagService.removeTag(selector);
  }
}
