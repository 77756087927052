<div [ngClass]="{ 'show d-flex justify-content-center': cookieModalService.isModalOpened() }"
     class="modal fade"
     tabindex="-1" role="dialog" aria-labelledby="cookieConsentModal" aria-hidden="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body"
           [style.height]="cookieModalService.isManagedModalOpened() ? '420px' : 'auto'">
        @if (cookieModalService.isManagedModalOpened()) {
          <div class="h-100 d-flex flex-column justify-content-between align-items-start">
            <h4>Privacy Preference Center</h4>
            <div class="cookie-text" style="margin-bottom: 10px;">
              When you visit any website, it may store or retrieve information on your browser, mostly in the form of
              cookies. This information might be about you, your preferences or your device and is mostly used to make
              the site work as you expect it to. The information does not usually directly identify you, but it can
              give you a more personalized web experience. Because we respect your right to privacy, you can choose
              not to allow some types of cookies. Click on the different category headings to find out more and change
              our default settings. However, blocking some types of cookies may impact your experience of the site and
              the services we are able to offer.
            </div>
            <h4>Manage Consent Preference</h4>
            <div class="d-flex flex-wrap w-100">
              <div class="pr">
                <hr />
                <div class="form-check-reverse form-switch">
                  <label class="form-check-label" for="strictCookieSwitch">Strictly Required Cookies</label>
                  <input class="form-check-input" type="checkbox" role="switch" id="strictCookieSwitch" disabled checked>
                </div>
                <hr />
                <div class="form-check-reverse form-switch">
                  <label class="form-check-label" for="performanceCookieSwitch">Performance Cookies</label>
                  <input class="form-check-input" type="checkbox" role="switch" id="performanceCookieSwitch" [(ngModel)]="performanceCookie">
                </div>
              </div>
              <div class="pr">
                <hr />
                <div class="form-check-reverse form-switch">
                  <label class="form-check-label" for="functionalCookieSwitch">Functional Cookies</label>
                  <input class="form-check-input" type="checkbox" role="switch" id="functionalCookieSwitch" [(ngModel)]="functionalCookie">
                </div>
                <hr />
                <div class="form-check-reverse form-switch">
                  <label class="form-check-label" for="targetingCookieSwitch">Targeting Cookies</label>
                  <input class="form-check-input" type="checkbox" role="switch" id="targetingCookieSwitch" [(ngModel)]="targetingCookie">
                </div>
              </div>
              <div class="pr">
                <hr />
                <div class="form-check-reverse form-switch">
                  <label class="form-check-label" for="socialMediaCookieSwitch">Social Media Cookies</label>
                  <input class="form-check-input" type="checkbox" role="switch" id="socialMediaCookieSwitch" [(ngModel)]="socialMediaCookie">
                </div>
              </div>
            </div>
            <div class="w-100 d-flex justify-content-end flex-lg-row pb-2 pt-2">
              <button class="default-btn contrast btn-sm p-2 m-1" (click)="cookieModalService.acceptAllCookieAnswer()">Allow All</button>
              <button class="default-btn secondary btn-sm p-2 m-1" (click)="acceptSelectedCookies()">Confirm My Choice</button>
            </div>
          </div>
        } @else {
          <div class="d-flex justify-content-between align-items-center">
            <div class="cookie-text flex-shrink-1" style="margin-right: 20px">This website uses cookies to personalize content and analyse traffic in order to
              offer you a better experience.
            </div>
            <div class="d-flex flex-row flex-wrap justify-content-end align-items-baseline">
              <button class="default-btn contrast btn-sm p-2 m-1" (click)="cookieModalService.acceptAllCookieAnswer()">Accept All</button>
              <button class="default-btn secondary btn-sm p-2 m-1" (click)="cookieModalService.openCustomizeModal()">Customize</button>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
