import {Component, OnInit, signal, WritableSignal} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {DefaultSubscriptionService} from "../../data/services/default-subscription.service";
import {take} from "rxjs";
import {SEOService} from "../../core/services/seo.service";

type Answer = {
  order: number,
  message: string
}

@Component({
  selector: 'app-unsubscription',
  templateUrl: './unsubscription.component.html',
  styleUrl: './unsubscription.component.scss'
})
export class UnsubscriptionComponent implements OnInit {

  private userId!: string;
  feedbackInProgress: WritableSignal<boolean> = signal(false);
  answerNumber: number | undefined = undefined;
  customAnswer: string = '';
  answers: any = {
    "0": {
      order: 0,
      message: 'I never signed up for your emails'
    },
    "1": {
      order: 1,
      message: 'The content is no longer relevant to me'
    },
    "2": {
      order: 2,
      message: 'You email to frequently'
    },
    "3": {
      order: 3,
      message: 'Other'
    }
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              private seoService: SEOService,
              private subService: DefaultSubscriptionService) {
  }

  ngOnInit(): void {
    this.seoService.setPageTitle('Cloudninja — Unsubscription feedback');

    if (this.route.snapshot.queryParamMap.has('id')) {
      this.userId = String(this.route.snapshot.queryParamMap.get('id'));
      this.subService.unsubscribeFromNews(this.userId)
        .pipe(take(1))
        .subscribe();
    } else {
      this.router.navigate(['/err?error=400'])
    }
  }

  resubscribeOnNews(): void {
    this.subService.resubscribeOnNews(this.userId)
      .pipe(take(1))
      .subscribe();
  }

  sendFeedback(): void {
    if (!this.feedbackInProgress()) {
      this.feedbackInProgress.set(true);
      const response = {
        user_id: this.userId,
        answer_order: this.answerNumber,
        answer_message: this.answerNumber !== 3 ? this.answers[String(this.answerNumber)].message : this.customAnswer
      }

      this.subService.sendFeedback(response)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.feedbackInProgress.set(false);
            this.router.navigate(['/']);
          },
          error: err => {
            console.log(err);
            this.feedbackInProgress.set(false);
          }
        });
    }
  }

  getAnswerObjects(): Answer[] {
    return Object.values(this.answers);
  }
}
