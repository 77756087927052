import {Component, Input, OnInit} from '@angular/core';
import {DataEntityHolder} from "../../../../core/types/data-entity-holder";

@Component({
  selector: 'app-what-we-offer',
  templateUrl: './what-we-offer.component.html',
  styleUrls: ['./what-we-offer.component.scss', '../section-common.scss']
})
export class WhatWeOfferComponent {

  secondOverviewSectionData: DataEntityHolder[] = [
    {
      name: "Discovery & Strategy",
      class: 'active',
    },
    {
      class: '',
      name: "UX/UI Design"
    },
    // {
    //   class: '',
    //   name: "iOS apps (Swift)"
    // },
    // {
    //   class: '',
    //   name: "Android apps (Java/Kotlin)"
    // },
    {
      class: '',
      name: "Managed IT Service"
    },
    {
      class: '',
      name: "Cloud Services"
    },
  ]
}
