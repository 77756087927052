<app-section name="what-we-do" [simpleHeight]="true">
  <div class="row overview-content">
    <h2>Our services</h2>
  </div>
  <div class="row">
    @for (card of serviceSectionData; track card.name) {
      <app-service-item class="col-lg-4 col-md-6 col-sm-12" [card]="card"></app-service-item>
    }
  </div>
</app-section>
