<app-section name="who-we-are">
  <div class="col-lg-6">
    <div class="overview-image">
      <img ngSrc="assets/img/choose-1.png" priority="2" alt="image" height="500" width="600">
    </div>
  </div>
  <div class="col-lg-6" style="margin: auto 0">
    <div class="overview-content">
      <h2>Who we are?</h2>
      <p>
        We are a distinguished IT company backed by a team of highly experienced professionals. With a cumulative
        wealth of knowledge and expertise in diverse technological domains, our experts have a proven track record of
        delivering outstanding solutions to clients across various industries. We take pride in our ability to
        understand the unique challenges faced by businesses and leverage our extensive experience to craft innovative,
        scalable, and cutting-edge IT solutions. As your trusted technology partner, we are committed to transforming
        your visions into reality and driving your business to new heights of success.
      </p>
    </div>
  </div>
</app-section>
