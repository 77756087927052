import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LayoutComponent} from "../layout/layout/layout.component";
import {NavBarComponent} from "../layout/nav-bar/nav-bar.component";
import {DropdownNavBarItemComponent} from "../layout/nav-bar/dropdown-nav-bar-item/dropdown-nav-bar-item.component";
import {SimpleNavBarItemComponent} from "../layout/nav-bar/simple-nav-bar-item/simple-nav-bar-item.component";
import {RouterLink, RouterOutlet} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CopyrightComponent} from "../layout/copyright/copyright.component";
import {SectionComponent} from "./components/section/section.component";
import {ThemeService} from "../core/services/theme.service";
import {SectionHeightUnitDirective} from "./directives/section-height-unit.directive";
import {CookieService} from "ngx-cookie-service";
import {CookieModalComponent} from '../layout/cookie-modal/cookie-modal.component';


@NgModule({
  declarations: [
    SectionComponent
  ],
  imports: [
    RouterLink,
    RouterOutlet,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SectionHeightUnitDirective
  ],
  exports: [
    RouterLink,
    RouterOutlet,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    SectionHeightUnitDirective,
    SectionComponent
  ]
})
export class SharedModule { }
