import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  private readonly baseUrl: string;

  constructor() {
    let subPath = environment.subPath;
    if (subPath !== null && subPath.length > 0) {
      subPath = subPath.replace("/", "");
      this.baseUrl = `${environment.apiUrl}/${subPath}`
    } else {
      this.baseUrl = `${environment.apiUrl}`
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestUrl = `${this.baseUrl}/${request.url}`;
    const headers: HttpHeaders = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append('Origin', environment.apiUrl)

    const newHttpRequest = request.clone({url: requestUrl, headers: headers});
    return next.handle(newHttpRequest);
  }
}
