import {Component, HostListener} from '@angular/core';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {

  protected readonly env = environment;

  routerObj = {
    main: {label: 'Home', link: '/', fragment: 'top', dropdown: false},
    about: {label: 'About Us', link: '/', fragment: 'who-we-are', dropdown: false},
    services: {label: 'Services', link: '/', fragment: 'what-we-do', dropdown: false},
    contact: {label: 'Contact', link: '/', fragment: 'contact-form', dropdown: false},
    law: {
      label: 'Legal',
      dropdown: true,
      child: [
        {label: 'Term of Use', link: '/law/terms', fragment: 'top', dropdown: false},
        {label: 'Privacy policy', link: '/law/policy',  fragment: 'top', dropdown: false},
      ]
    },
  }

  @HostListener('window:scroll', ['$event'])
  monitorScrolled() {
    return window.scrollY >= 5;
  }

  getAsArray() {
    return Object.values(this.routerObj);
  }
}
