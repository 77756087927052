import {Component, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {SEOService} from "../../../core/services/seo.service";

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss', '../law-common.scss']
})
export class PolicyComponent implements OnInit {

  protected readonly env = environment;

  constructor(private seoService: SEOService) {
  }

  ngOnInit() {
    this.seoService.setPageTitle('Cloudninja - Private Policies');
  }
}
