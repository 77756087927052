<div class="home-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="main-banner-content">
              <h1>Encourage innovation with trusted <span class="main-color-gradient-text">Technology Solutions</span></h1>
              <h5> &#8212; "Innovate, Integrate, Inspire."</h5>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="banner-image">
              <img ngSrc="/assets/img/home-font.png" priority="1" alt="image" height="550" width="600">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="grey-cr"></div>
</div>
