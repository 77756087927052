import {Component} from '@angular/core';
import {CookieModalService} from "../cookie-modal.service";
import {CookieLevelType} from "../../core/types/CookieLevelType";

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrl: './cookie-modal.component.scss'
})
export class CookieModalComponent {

  socialMediaCookie: boolean = false;
  performanceCookie: boolean = false;
  functionalCookie: boolean = false;
  targetingCookie: boolean = false;

  constructor(protected cookieModalService: CookieModalService) {
  }

  acceptSelectedCookies() {
    const selectedCookies: CookieLevelType[] = ['Required'];
    if (this.performanceCookie && this.functionalCookie && this.targetingCookie && this.socialMediaCookie) {
      selectedCookies[0] = 'All';
    } else {
      if (this.performanceCookie) {
        selectedCookies.push('Performance');
      }
      if (this.functionalCookie) {
        selectedCookies.push('Functional');
      }
      if (this.targetingCookie) {
        selectedCookies.push('Targeting');
      }
      if (this.socialMediaCookie) {
        selectedCookies.push('Social Media');
      }
    }

    this.cookieModalService.acceptSelectedCookies(selectedCookies);
  }
}
