import {Component, Input} from '@angular/core';
import {NavLink} from "../../../core/types/nav-link";

@Component({
  selector: 'app-dropdown-nav-bar-item',
  templateUrl: './dropdown-nav-bar-item.component.html',
  styleUrls: ['./dropdown-nav-bar-item.component.scss']
})
export class DropdownNavBarItemComponent {

  @Input()
  data!: NavLink;
}
