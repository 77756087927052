import {Component, Input} from '@angular/core';
import {DataEntityHolder} from "../../../../core/types/data-entity-holder";

@Component({
  selector: 'app-way-to-excellence',
  templateUrl: './way-to-excellence.component.html',
  styleUrls: ['./way-to-excellence.component.scss', '../section-common.scss']
})
export class WayToExcellenceComponent {

  excellenceOptions: DataEntityHolder[] = [
    {
      name: 'Prior on a Client',
      class: 'active'
    },
    {
      name: 'Take Leadership',
      class: ''
    },
    {
      name: 'Accept Responsiblities',
      class: ''
    },
    {
      name: 'Challenge Yourself',
      class: ''
    },
    {
      name: 'Always Learning',
      class: ''
    },
  ]
}
