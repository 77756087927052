import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-form-animation',
  templateUrl: './contact-form-animation.component.html',
  styleUrls: ['./contact-form-animation.component.scss']
})
export class ContactFormAnimationComponent {

}
