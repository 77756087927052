import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CookieModalService} from "../cookie-modal.service";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit {
  constructor(private cookieManagementService: CookieModalService) {
  }

  ngOnInit() {
    this.cookieManagementService.initializeCookieFlow();
  }
}
