import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ContactInfo} from "../entities/contact-info";
import {Observable} from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ContactService {

  constructor(private http: HttpClient) {
  }

  public sendContactInfoToServer(contact: ContactInfo): Observable<any> {
    return this.http.post("contact", contact, {responseType: "text"});
  }
}
