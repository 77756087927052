import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DataEntityHolder} from "../../../../../core/types/data-entity-holder";

@Component({
  selector: 'app-service-item',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './service-item.component.html',
  styleUrl: './service-item.component.scss'
})
export class ServiceItemComponent {

  @Input() card!: DataEntityHolder;

}
