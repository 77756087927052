import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./module/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'law',
    loadChildren: () => import('./module/law/law.module').then(m => m.LawModule)
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./module/unsubscription/unsubscription.module').then(m => m.UnsubscriptionModule)
  },
  {
    path: '**',
    loadChildren: () => import('./module/error/error-page.module').then(m => m.ErrorPageModule)
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      enableViewTransitions: true,
      scrollOffset: [0, 80]
    }),
    CommonModule
  ],
})
export class AppRouterModule { }
