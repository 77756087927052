export const environment = {
  production: false,
  officialCompanyName: 'Cloudninja',
  officialCompanyAddress: '4035 Heavens, Los Angeles, California',
  websiteName: 'Cloudninja',
  cookieBaseDomain: 'cloudninja.com.ua',
  websiteUrl: 'https://dev.cloudninja.com.ua',
  apiUrl: 'https://api.dev.business.cloudninja.com.ua',
  subPath: 'v2',
  contactPhone: '+421 123 456 789',
  countries: {
    'ua': 'Ukraine',
    'sk': 'Slovakia'
  },
  emails: {
    contact: 'contact@cloudninja.com.ua',
    policy: 'policy@cloudninja.com.ua',
    support: 'support@cloudninja.com.ua'
  },
  dates: {
    release: '24/11/2023',
    termLastUpdate: 'July 25, 2023',
    policyLastUpdate: 'July 25, 2023'
  },
  socialLink: {
    linkedIn: 'https://www.linkedin.com/company/clnj',
    twitter: '',
    facebook: 'https://www.facebook.com/groups/1017942679289799',
    upwork: 'https://www.upwork.com/agencies/1731625962167934976/'
  },
  gtmId: 'K9CFDVRB'
};


