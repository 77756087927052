import {Component, Input, signal, WritableSignal} from '@angular/core';
import {ThemeSchema, ThemeService} from "../../../core/services/theme.service";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrl: './section.component.scss'
})
export class SectionComponent {

  @Input({required: true}) name!: string;
  @Input({required: false}) index: WritableSignal<number> = signal(0);
  _simpleHeight: WritableSignal<"%" | "svh"> = signal('svh');

  constructor(private themeService: ThemeService) {
  }

  getBackgroundStyle(): string {
    const sectionOrder = this.themeService.getSectionOrder(this.name);
    const currentThemeSchema: ThemeSchema = this.themeService.getCurrentThemeSchema();
    return !!(sectionOrder & 1) ? currentThemeSchema.odd : currentThemeSchema.even;
  }

  @Input({required: false})
  set simpleHeight(val: boolean) {
    this._simpleHeight.set(val ? '%' : 'svh');
  }
}
