import {Component, Input, OnInit} from '@angular/core';
import {DataEntityHolder} from "../../../../core/types/data-entity-holder";

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.scss', '../section-common.scss']
})
export class WhatWeDoComponent {

  serviceSectionData: DataEntityHolder[] = [
    {
      icon: 'pe-7s-loop',
      name: 'Organize DevOps Flow',
      description: 'Efficiently streamline and optimize the entire DevOps workflow for seamless software development and deployment.'
    },
    {
      icon: 'pe-7s-cloud-upload',
      name: 'Cloud migration',
      description: 'Transition your IT infrastructure and applications to the cloud for enhanced scalability, flexibility, and cost-efficiency.'
    },
    {
      icon: 'pe-7s-tools',
      name: 'Infrastructure automation',
      description: 'Automate your infrastructure provisioning to increase efficiency, reduce human errors, and accelerate software delivery.',
    },
    {
      icon: 'pe-7s-display1',
      name: 'Performance optimization',
      description: 'Enhance the overall performance, reliability, and responsiveness of your infrastructure through comprehensive analysis and strategic optimizations.'
    },
    {
      icon: 'pe-7s-piggy',
      name: 'Budget auditing',
      description: 'Ensure optimal cloud cost management and resource utilization through meticulous budget auditing and cost optimization strategies.'
    },
    {
      icon: 'pe-7s-users',
      name: 'Team Management',
      description: 'Efficiently lead and coordinate IT teams, fostering collaboration, innovation, and productivity to achieve organizational goals.'
    },
  ]
}
