import {Component, OnInit, signal, WritableSignal} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SEOService} from "../../core/services/seo.service";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  host: {
    'style.height': '100%'
  }
})
export class ErrorPageComponent implements OnInit {

  errorNumber: WritableSignal<string> = signal("404");

  pageErrorMap: { [key: string]: string } = {
    "400": "Something goes wrong",
    "401": "Not Authenticated",
    "403": "Forbidden",
    "404": "Page not found",
    "500": "Server error"
  };

  constructor(private route: ActivatedRoute,
              private seoService: SEOService) {
    const queryParamMap = this.route.snapshot.queryParamMap;
    const errorStr = queryParamMap.has('error') ? String(queryParamMap.get('error')) : '';

    this.errorNumber.update(value =>
      value !== errorStr
      && Object.keys(this.pageErrorMap).includes(errorStr)
        ? errorStr
        : this.errorNumber());
  }

  ngOnInit(): void {
    this.seoService.setPageTitle(`Cloudninja — ${this.errorNumber()} ${this.pageErrorMap[this.errorNumber()]}`);
  }


  getError() {
    return this.pageErrorMap[this.errorNumber()];
  }
}
