import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {SEOService} from "../../../core/services/seo.service";


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss', '../law-common.scss']
})
export class TermsComponent implements OnInit {

  protected readonly env = environment;

  constructor(private seoService: SEOService) {
  }

  ngOnInit() {
    this.seoService.setPageTitle('Cloudninja - Terms of Use');
  }
}
