import {Component, signal, WritableSignal} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {catchError, EMPTY, finalize, Observable, switchMap, take, timer} from "rxjs";
import {environment} from "../../../../../../environments/environment";
import {DefaultSubscriptionService} from "../../../../../data/services/default-subscription.service";
import {IRequestProcessing} from "../../../../../core/interfaces/IRequestProcessing";
import {HttpErrorResponse} from "@angular/common/http";
import {RequestProcessingType} from "../../../../../core/types/RequestProcessingType";

@Component({
  selector: 'app-footer-contact-block',
  templateUrl: './footer-contact-block.component.html',
  styleUrls: ['./footer-contact-block.component.scss', "../footer-common.scss"]
})
export class FooterContactBlockComponent implements IRequestProcessing<any>{

  protected readonly env = environment;

  private readonly form: FormGroup;
  requestStatus: WritableSignal<RequestProcessingType> = signal('None');

  constructor(private fb: FormBuilder,
              private subService: DefaultSubscriptionService) {
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])]
    });
  }

  contact: any = {
    phone: {
      label: 'Phone:',
      value: this.env.contactPhone,
      ref: 'tel:' + this.env.contactPhone
    },
    email: {
      label: 'Email:',
      value: this.env.emails.contact,
      ref: 'mailto:' + this.env.emails.contact
    }
    // address: {
    //   label: 'Address:',
    //   value: this.env.officialCompanyAddress,
    //   ref: 'way on google maps'
    // }
  }

  getForm(): FormGroup {
    return this.form;
  }

  getContactAsArray(): { label: string, value: string, ref: string }[] {
    return Object.values(this.contact);
  }

  onSubmit() {
    if (this.requestStatus() === 'None' && this.form.valid) {
      this.requestStatus.set('InProgress');
      this.subService.subscribeOnNews(this.form.getRawValue())
        .pipe(
          switchMap(() => this.onRequestSuccess(null)),
          take(1),
          catchError(err => this.onRequestError(err)),
          finalize(() => this.onRequestComplete()))
        .subscribe()
    }
  }

  onRequestSuccess(value: any): Observable<any> {
    this.requestStatus.set('Successful');
    this.form.patchValue({email: ''});
    return timer(5000);
  }

  onRequestError(err: HttpErrorResponse): Observable<any> {
    this.requestStatus.set('Failed');
    return timer(5000);
  }

  onRequestComplete(): void {
    this.requestStatus.set('None');
  }
}
