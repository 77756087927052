<app-section name="contact-form">
  <div class="col-lg-6">
    <div class="overview-content">
      <h2 style="text-align: center">Fill the form and we reach on you</h2>
      <form [formGroup]="form" class="newsletter-form p-xl-0 p-lg-0 p-md-3 p-sm-3 p-3">
        <div style="margin: 0; padding: 0; max-width: 400px;">
          <input formControlName="email" type="email" class="form-control input-newsletter" placeholder="Email"
                 name="email"
                 [ngClass]="{'is-valid': isValid('email'), 'is-invalid': isInvalid('email')}"
                 required="true" autocomplete="off">
          <div class="invalid-feedback">
            {{ getErrorMessage('email') }}
          </div>
        </div>
        <div style="margin: 0; padding: 0; max-width: 400px;">
          <input formControlName="name" type="text" class="form-control input-newsletter" placeholder="Your name"
                 name="name"
                 [ngClass]="{'is-valid': isValid('name'), 'is-invalid': isInvalid('name')}"
                 required="true" autocomplete="off">
          <div class="invalid-feedback">
            {{ getErrorMessage('name') }}
          </div>
        </div>
        <div style="margin: 0; padding: 0; width: 100%; max-width: 400px;">
          <input formControlName="company" type="text" class="form-control input-newsletter"
                 [ngClass]="{'is-valid': isValid('company'), 'is-invalid': isInvalid('company')}"
                 placeholder="Organization name (optional)" name="company" required="false" autocomplete="off">
          <div class="invalid-feedback">
            {{ getErrorMessage('company') }}
          </div>
        </div>
        <div style="margin: 0; padding: 0; max-width: 400px;">
          <input formControlName="phone" type="tel" class="form-control input-newsletter" placeholder="Phone (optional)"
                 name="phone"
                 [ngClass]="{'is-valid': isValid('phone'), 'is-invalid': isInvalid('phone')}"
                 required="false" autocomplete="off">
          <div class="invalid-feedback">
            {{ getErrorMessage('phone') }}
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button class="col-xl-5 col-lg-6 col-md-7 col-sm-12 col-12 default-btn" type="button" role="button"
                  [style.background-color]="(requestStatus() === 'None') ? 'var(--app-color-main)' : 'rgba(var(--bs-light-rgb),var(--bs-bg-opacity))'"
                  (click)="onSubmit()">
            @if (requestStatus() === 'InProgress') {
              <div class="request-loading">
                <i class="spinner-border spinner-border-sm" aria-hidden="true"></i> Sending...
              </div>
            } @else if (requestStatus() === 'Successful') {
              <div class="request-success">
                <i class="fa-solid fa-circle-check"></i> Successful
              </div>
            } @else if (requestStatus() === 'Failed') {
              <div class="request-failed">
                <i class="fa-solid fa-circle-xmark"></i> Failed
              </div>
            } @else {
              Contact Us<span></span>
            }
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-lg-6">
    <app-contact-form-animation></app-contact-form-animation>
  </div>
</app-section>
