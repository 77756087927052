<section class="footer-area section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget small-pad-view">
          <div class="footer-heading">
            <h3>About Us</h3>
          </div>
          <p>We are a dynamic IT company providing innovative solutions and top-notch services to empower businesses with cutting-edge technology.</p>
          <ul class="footer-social">
            @for (social of footerSocial; track social.label) {
              <li>
                <a [href]="social.link">
                  <i [class]="social.icon" style="font-size:30px; color: #fff"></i>
                </a>
              </li>
            }
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-6">
        <div class="single-footer-widget small-pad-view">
          <div class="footer-heading">
            <h3>Our Services</h3>
          </div>
          <ul class="footer-quick-links">
            @for (ql of footerQuickLinksServices; track ql.label) {
              <li><a routerLink="/" [fragment]="ql.fragment">{{ ql.name }}</a></li>
            }
          </ul>
        </div>
      </div>
      <div class="col-lg-2 col-md-6 col-sm-6">
        <div class="single-footer-widget small-pad-view">
          <div class="footer-heading">
            <h3>Quick Links</h3>
          </div>
          <ul class="footer-quick-links">
            @for (ql of footerQuickLinks; track ql.label) {
              <li><a [routerLink]="ql.link" [fragment]="ql.fragment">{{ ql.name }}</a></li>
            }
          </ul>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-footer-widget small-pad-view">
          <div class="footer-heading">
            <h3>Contact Information</h3>
          </div>
          <app-footer-contact-block></app-footer-contact-block>
        </div>
      </div>
    </div>
  </div>
</section>
