import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {NavLink} from "../../../core/types/nav-link";
import {ActivatedRoute} from "@angular/router";
import {ViewportScroller} from "@angular/common";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter} from "rxjs";

@Component({
  selector: 'app-simple-nav-bar-item',
  templateUrl: './simple-nav-bar-item.component.html',
  styleUrls: ['./simple-nav-bar-item.component.scss']
})
export class SimpleNavBarItemComponent implements OnInit, AfterViewInit {

  @Input()
  data!: NavLink;

  constructor(private route: ActivatedRoute,
              private viewportScroller: ViewportScroller) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    // this.route.fragment
    //   .pipe(takeUntilDestroyed())
    //   .subscribe((value) => {
    //     if (value) {
    //       this.viewportScroller.scrollToAnchor(value);
    //     }
    //   });
  }

  scroll() {
    if (this.data.fragment) {
      const el = document.getElementById(this.data.fragment);
      el?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }
  }
}
