import {Injectable} from '@angular/core';
import {SharedModule} from "../../shared/shared.module";

export type Theme = 'light' | 'dark' | 'system';
export type ThemeSchema = {
  main: string,
  odd: string,
  even: string
};
type ThemeSchemaMap = {
  [key in Theme]: ThemeSchema
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private theme: Theme = "light";
  private themeSchemeMap: ThemeSchemaMap = {
    "light": {main: 'bg-white', odd: 'bg-light', even: 'bg-white'},
    "dark": {main: 'bg-dark', odd: 'bg-lightgrey', even: 'bg-dark'},
    "system": {main: 'bg-light', odd: '', even: ''}
  }

  private readonly mainSectionOrder: { [key: string]: number } = {
    'home': 0,
    'who-we-are': 1,
    'what-we-do': 2,
    'what-we-offer': 3,
    'way-to-excellence': 4,
    'contact-form': 5,
    'footer': 6
  }

  getSectionOrder(name: string): number {
    return this.mainSectionOrder[name];
  }

  getTheme(): Theme {
    return this.theme;
  }

  getCurrentThemeSchema() {
    return this.themeSchemeMap[this.getTheme()];
  }

  constructor() {
  }
}
