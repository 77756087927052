import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[sectionHeightUnit]',
})
export class SectionHeightUnitDirective {

  @Input() sectionHeightUnit: "%" | "svh" = 'svh';

  @HostBinding('style.height')
  get height(): string {
    return `100${this.sectionHeightUnit}`;
  }



}
